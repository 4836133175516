import { default as findingfieldsui29WtFIhSMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/findingfields.vue?macro=true";
import { default as indexBcV06gsH67Meta } from "/app/frontend/src/pages/designs/[projectTypeId]/index.vue?macro=true";
import { default as notes1lljo3RWUfMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/notes.vue?macro=true";
import { default as pdfdesignerpa1OsJJF2tMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue?macro=true";
import { default as reportfieldsfuVToEdy0TMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/reportfields.vue?macro=true";
import { default as _91projectTypeId_939dMEgBikuGMeta } from "/app/frontend/src/pages/designs/[projectTypeId].vue?macro=true";
import { default as index64g2t5Ct3VMeta } from "/app/frontend/src/pages/designs/index.vue?macro=true";
import { default as privateZd9ocD0B9kMeta } from "/app/frontend/src/pages/designs/private.vue?macro=true";
import { default as indexMf5jkORaCRMeta } from "/app/frontend/src/pages/index.vue?macro=true";
import { default as licensezxLCBvHvmuMeta } from "/app/frontend/src/pages/license.vue?macro=true";
import { default as autoAUBHU94pOcMeta } from "/app/frontend/src/pages/login/auto.vue?macro=true";
import { default as index6tsFOSXP2dMeta } from "/app/frontend/src/pages/login/index.vue?macro=true";
import { default as localTsalOUwb3lMeta } from "/app/frontend/src/pages/login/local.vue?macro=true";
import { default as callback3EiA7Q6aXcMeta } from "/app/frontend/src/pages/login/oidc/[authProviderId]/callback.vue?macro=true";
import { default as reauthnwza5tMdRrMeta } from "/app/frontend/src/pages/login/reauth.vue?macro=true";
import { default as _91noteId_93O3Chu7dCKAMeta } from "/app/frontend/src/pages/notes/personal/[noteId].vue?macro=true";
import { default as index1DPL1wATpSMeta } from "/app/frontend/src/pages/notes/personal/index.vue?macro=true";
import { default as personaljYYq1UwqsFMeta } from "/app/frontend/src/pages/notes/personal.vue?macro=true";
import { default as archivecDgAYAZlG7Meta } from "/app/frontend/src/pages/projects/[projectId]/archive.vue?macro=true";
import { default as designerhqz1YsUYmLMeta } from "/app/frontend/src/pages/projects/[projectId]/designer.vue?macro=true";
import { default as historyjTmSOIl5rAMeta } from "/app/frontend/src/pages/projects/[projectId]/history.vue?macro=true";
import { default as indexSawsiN7KwoMeta } from "/app/frontend/src/pages/projects/[projectId]/index.vue?macro=true";
import { default as _91historyDate_93IaWVETKT6qMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue?macro=true";
import { default as indexxeJq2tqifWMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue?macro=true";
import { default as indexTga67sANQcMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/index.vue?macro=true";
import { default as notesivEEU8zD53Meta } from "/app/frontend/src/pages/projects/[projectId]/notes.vue?macro=true";
import { default as publishDpcUafriHgMeta } from "/app/frontend/src/pages/projects/[projectId]/publish.vue?macro=true";
import { default as _91historyDate_931ah68vjHKRMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue?macro=true";
import { default as indexn4p8ktfFGqMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue?macro=true";
import { default as indexcqKZLTJe2ZMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/index.vue?macro=true";
import { default as _91historyDate_93JXyNSK4JEtMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue?macro=true";
import { default as indexAC5wB9IXR9Meta } from "/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue?macro=true";
import { default as reportingOzsAQlMEoMMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting.vue?macro=true";
import { default as _91projectId_93g827x3LaLwMeta } from "/app/frontend/src/pages/projects/[projectId].vue?macro=true";
import { default as _91archiveId_93MmIC1PRJWoMeta } from "/app/frontend/src/pages/projects/archived/[archiveId].vue?macro=true";
import { default as indexScrE0j62szMeta } from "/app/frontend/src/pages/projects/archived/index.vue?macro=true";
import { default as finishedYxzpNu9iJTMeta } from "/app/frontend/src/pages/projects/finished.vue?macro=true";
import { default as indexOZ0RFyXkAWMeta } from "/app/frontend/src/pages/projects/index.vue?macro=true";
import { default as newV3pJ8pIcZWMeta } from "/app/frontend/src/pages/projects/new.vue?macro=true";
import { default as _91historyDate_93ry9wFVcNwEMeta } from "/app/frontend/src/pages/templates/[templateId]/history/[historyDate].vue?macro=true";
import { default as index9s2xoFsBJ0Meta } from "/app/frontend/src/pages/templates/[templateId]/index.vue?macro=true";
import { default as fromfindingaPH1KL2e7qMeta } from "/app/frontend/src/pages/templates/fromfinding.vue?macro=true";
import { default as indexZJJx716aGUMeta } from "/app/frontend/src/pages/templates/index.vue?macro=true";
import { default as apitokensxE6nfZg71RMeta } from "/app/frontend/src/pages/users/[userId]/apitokens.vue?macro=true";
import { default as identitiesjIaI0LHVy0Meta } from "/app/frontend/src/pages/users/[userId]/identities.vue?macro=true";
import { default as indexgmy2EQd0ejMeta } from "/app/frontend/src/pages/users/[userId]/index.vue?macro=true";
import { default as mfaRfCUJxFGADMeta } from "/app/frontend/src/pages/users/[userId]/mfa.vue?macro=true";
import { default as reset_45password0vcTOiLbHKMeta } from "/app/frontend/src/pages/users/[userId]/reset-password.vue?macro=true";
import { default as _91userId_93pAPqCZuHcAMeta } from "/app/frontend/src/pages/users/[userId].vue?macro=true";
import { default as indext9a7DYRpPHMeta } from "/app/frontend/src/pages/users/index.vue?macro=true";
import { default as newmFBbp6MZGyMeta } from "/app/frontend/src/pages/users/new.vue?macro=true";
import { default as disableZGe3JI0Z4mMeta } from "/app/frontend/src/pages/users/self/admin/disable.vue?macro=true";
import { default as enablemD4rzmQWfIMeta } from "/app/frontend/src/pages/users/self/admin/enable.vue?macro=true";
import { default as apitokensNzrX0HbFb7Meta } from "/app/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexqsOtAAd0dxMeta } from "/app/frontend/src/pages/users/self/index.vue?macro=true";
import { default as publickeysaNUZ8hwiZQMeta } from "/app/frontend/src/pages/users/self/publickeys.vue?macro=true";
import { default as securityFhHzVi7ffMMeta } from "/app/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfAUDD3dmxkeMeta } from "/app/frontend/src/pages/users/self.vue?macro=true";
export default [
  {
    name: _91projectTypeId_939dMEgBikuGMeta?.name ?? undefined,
    path: _91projectTypeId_939dMEgBikuGMeta?.path ?? "/designs/:projectTypeId()",
    meta: _91projectTypeId_939dMEgBikuGMeta || {},
    alias: _91projectTypeId_939dMEgBikuGMeta?.alias || [],
    redirect: _91projectTypeId_939dMEgBikuGMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId].vue").then(m => m.default || m),
    children: [
  {
    name: findingfieldsui29WtFIhSMeta?.name ?? "designs-projectTypeId-findingfields",
    path: findingfieldsui29WtFIhSMeta?.path ?? "findingfields",
    meta: findingfieldsui29WtFIhSMeta || {},
    alias: findingfieldsui29WtFIhSMeta?.alias || [],
    redirect: findingfieldsui29WtFIhSMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/findingfields.vue").then(m => m.default || m)
  },
  {
    name: indexBcV06gsH67Meta?.name ?? "designs-projectTypeId",
    path: indexBcV06gsH67Meta?.path ?? "",
    meta: indexBcV06gsH67Meta || {},
    alias: indexBcV06gsH67Meta?.alias || [],
    redirect: indexBcV06gsH67Meta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/index.vue").then(m => m.default || m)
  },
  {
    name: notes1lljo3RWUfMeta?.name ?? "designs-projectTypeId-notes",
    path: notes1lljo3RWUfMeta?.path ?? "notes",
    meta: notes1lljo3RWUfMeta || {},
    alias: notes1lljo3RWUfMeta?.alias || [],
    redirect: notes1lljo3RWUfMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/notes.vue").then(m => m.default || m)
  },
  {
    name: pdfdesignerpa1OsJJF2tMeta?.name ?? "designs-projectTypeId-pdfdesigner",
    path: pdfdesignerpa1OsJJF2tMeta?.path ?? "pdfdesigner",
    meta: pdfdesignerpa1OsJJF2tMeta || {},
    alias: pdfdesignerpa1OsJJF2tMeta?.alias || [],
    redirect: pdfdesignerpa1OsJJF2tMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue").then(m => m.default || m)
  },
  {
    name: reportfieldsfuVToEdy0TMeta?.name ?? "designs-projectTypeId-reportfields",
    path: reportfieldsfuVToEdy0TMeta?.path ?? "reportfields",
    meta: reportfieldsfuVToEdy0TMeta || {},
    alias: reportfieldsfuVToEdy0TMeta?.alias || [],
    redirect: reportfieldsfuVToEdy0TMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/reportfields.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index64g2t5Ct3VMeta?.name ?? "designs",
    path: index64g2t5Ct3VMeta?.path ?? "/designs",
    meta: index64g2t5Ct3VMeta || {},
    alias: index64g2t5Ct3VMeta?.alias || [],
    redirect: index64g2t5Ct3VMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/index.vue").then(m => m.default || m)
  },
  {
    name: privateZd9ocD0B9kMeta?.name ?? "designs-private",
    path: privateZd9ocD0B9kMeta?.path ?? "/designs/private",
    meta: privateZd9ocD0B9kMeta || {},
    alias: privateZd9ocD0B9kMeta?.alias || [],
    redirect: privateZd9ocD0B9kMeta?.redirect,
    component: () => import("/app/frontend/src/pages/designs/private.vue").then(m => m.default || m)
  },
  {
    name: indexMf5jkORaCRMeta?.name ?? "index",
    path: indexMf5jkORaCRMeta?.path ?? "/",
    meta: indexMf5jkORaCRMeta || {},
    alias: indexMf5jkORaCRMeta?.alias || [],
    redirect: indexMf5jkORaCRMeta?.redirect,
    component: () => import("/app/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: licensezxLCBvHvmuMeta?.name ?? "license",
    path: licensezxLCBvHvmuMeta?.path ?? "/license",
    meta: licensezxLCBvHvmuMeta || {},
    alias: licensezxLCBvHvmuMeta?.alias || [],
    redirect: licensezxLCBvHvmuMeta?.redirect,
    component: () => import("/app/frontend/src/pages/license.vue").then(m => m.default || m)
  },
  {
    name: autoAUBHU94pOcMeta?.name ?? "login-auto",
    path: autoAUBHU94pOcMeta?.path ?? "/login/auto",
    meta: autoAUBHU94pOcMeta || {},
    alias: autoAUBHU94pOcMeta?.alias || [],
    redirect: autoAUBHU94pOcMeta?.redirect,
    component: () => import("/app/frontend/src/pages/login/auto.vue").then(m => m.default || m)
  },
  {
    name: index6tsFOSXP2dMeta?.name ?? "login",
    path: index6tsFOSXP2dMeta?.path ?? "/login",
    meta: index6tsFOSXP2dMeta || {},
    alias: index6tsFOSXP2dMeta?.alias || [],
    redirect: index6tsFOSXP2dMeta?.redirect,
    component: () => import("/app/frontend/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: localTsalOUwb3lMeta?.name ?? "login-local",
    path: localTsalOUwb3lMeta?.path ?? "/login/local",
    meta: localTsalOUwb3lMeta || {},
    alias: localTsalOUwb3lMeta?.alias || [],
    redirect: localTsalOUwb3lMeta?.redirect,
    component: () => import("/app/frontend/src/pages/login/local.vue").then(m => m.default || m)
  },
  {
    name: callback3EiA7Q6aXcMeta?.name ?? "login-oidc-authProviderId-callback",
    path: callback3EiA7Q6aXcMeta?.path ?? "/login/oidc/:authProviderId()/callback",
    meta: callback3EiA7Q6aXcMeta || {},
    alias: callback3EiA7Q6aXcMeta?.alias || [],
    redirect: callback3EiA7Q6aXcMeta?.redirect,
    component: () => import("/app/frontend/src/pages/login/oidc/[authProviderId]/callback.vue").then(m => m.default || m)
  },
  {
    name: reauthnwza5tMdRrMeta?.name ?? "login-reauth",
    path: reauthnwza5tMdRrMeta?.path ?? "/login/reauth",
    meta: reauthnwza5tMdRrMeta || {},
    alias: reauthnwza5tMdRrMeta?.alias || [],
    redirect: reauthnwza5tMdRrMeta?.redirect,
    component: () => import("/app/frontend/src/pages/login/reauth.vue").then(m => m.default || m)
  },
  {
    name: personaljYYq1UwqsFMeta?.name ?? undefined,
    path: personaljYYq1UwqsFMeta?.path ?? "/notes/personal",
    meta: personaljYYq1UwqsFMeta || {},
    alias: personaljYYq1UwqsFMeta?.alias || [],
    redirect: personaljYYq1UwqsFMeta?.redirect,
    component: () => import("/app/frontend/src/pages/notes/personal.vue").then(m => m.default || m),
    children: [
  {
    name: _91noteId_93O3Chu7dCKAMeta?.name ?? "notes-personal-noteId",
    path: _91noteId_93O3Chu7dCKAMeta?.path ?? ":noteId()",
    meta: _91noteId_93O3Chu7dCKAMeta || {},
    alias: _91noteId_93O3Chu7dCKAMeta?.alias || [],
    redirect: _91noteId_93O3Chu7dCKAMeta?.redirect,
    component: () => import("/app/frontend/src/pages/notes/personal/[noteId].vue").then(m => m.default || m)
  },
  {
    name: index1DPL1wATpSMeta?.name ?? "notes-personal",
    path: index1DPL1wATpSMeta?.path ?? "",
    meta: index1DPL1wATpSMeta || {},
    alias: index1DPL1wATpSMeta?.alias || [],
    redirect: index1DPL1wATpSMeta?.redirect,
    component: () => import("/app/frontend/src/pages/notes/personal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91projectId_93g827x3LaLwMeta?.name ?? undefined,
    path: _91projectId_93g827x3LaLwMeta?.path ?? "/projects/:projectId()",
    meta: _91projectId_93g827x3LaLwMeta || {},
    alias: _91projectId_93g827x3LaLwMeta?.alias || [],
    redirect: _91projectId_93g827x3LaLwMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId].vue").then(m => m.default || m),
    children: [
  {
    name: archivecDgAYAZlG7Meta?.name ?? "projects-projectId-archive",
    path: archivecDgAYAZlG7Meta?.path ?? "archive",
    meta: archivecDgAYAZlG7Meta || {},
    alias: archivecDgAYAZlG7Meta?.alias || [],
    redirect: archivecDgAYAZlG7Meta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/archive.vue").then(m => m.default || m)
  },
  {
    name: designerhqz1YsUYmLMeta?.name ?? "projects-projectId-designer",
    path: designerhqz1YsUYmLMeta?.path ?? "designer",
    meta: designerhqz1YsUYmLMeta || {},
    alias: designerhqz1YsUYmLMeta?.alias || [],
    redirect: designerhqz1YsUYmLMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/designer.vue").then(m => m.default || m)
  },
  {
    name: historyjTmSOIl5rAMeta?.name ?? "projects-projectId-history",
    path: historyjTmSOIl5rAMeta?.path ?? "history",
    meta: historyjTmSOIl5rAMeta || {},
    alias: historyjTmSOIl5rAMeta?.alias || [],
    redirect: historyjTmSOIl5rAMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/history.vue").then(m => m.default || m)
  },
  {
    name: indexSawsiN7KwoMeta?.name ?? "projects-projectId",
    path: indexSawsiN7KwoMeta?.path ?? "",
    meta: indexSawsiN7KwoMeta || {},
    alias: indexSawsiN7KwoMeta?.alias || [],
    redirect: indexSawsiN7KwoMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: notesivEEU8zD53Meta?.name ?? undefined,
    path: notesivEEU8zD53Meta?.path ?? "notes",
    meta: notesivEEU8zD53Meta || {},
    alias: notesivEEU8zD53Meta?.alias || [],
    redirect: notesivEEU8zD53Meta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes.vue").then(m => m.default || m),
    children: [
  {
    name: _91historyDate_93IaWVETKT6qMeta?.name ?? "projects-projectId-notes-noteId-history-historyDate",
    path: _91historyDate_93IaWVETKT6qMeta?.path ?? ":noteId()/history/:historyDate()",
    meta: _91historyDate_93IaWVETKT6qMeta || {},
    alias: _91historyDate_93IaWVETKT6qMeta?.alias || [],
    redirect: _91historyDate_93IaWVETKT6qMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: indexxeJq2tqifWMeta?.name ?? "projects-projectId-notes-noteId",
    path: indexxeJq2tqifWMeta?.path ?? ":noteId()",
    meta: indexxeJq2tqifWMeta || {},
    alias: indexxeJq2tqifWMeta?.alias || [],
    redirect: indexxeJq2tqifWMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTga67sANQcMeta?.name ?? "projects-projectId-notes",
    path: indexTga67sANQcMeta?.path ?? "",
    meta: indexTga67sANQcMeta || {},
    alias: indexTga67sANQcMeta?.alias || [],
    redirect: indexTga67sANQcMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: publishDpcUafriHgMeta?.name ?? "projects-projectId-publish",
    path: publishDpcUafriHgMeta?.path ?? "publish",
    meta: publishDpcUafriHgMeta || {},
    alias: publishDpcUafriHgMeta?.alias || [],
    redirect: publishDpcUafriHgMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/publish.vue").then(m => m.default || m)
  },
  {
    name: reportingOzsAQlMEoMMeta?.name ?? undefined,
    path: reportingOzsAQlMEoMMeta?.path ?? "reporting",
    meta: reportingOzsAQlMEoMMeta || {},
    alias: reportingOzsAQlMEoMMeta?.alias || [],
    redirect: reportingOzsAQlMEoMMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting.vue").then(m => m.default || m),
    children: [
  {
    name: _91historyDate_931ah68vjHKRMeta?.name ?? "projects-projectId-reporting-findings-findingId-history-historyDate",
    path: _91historyDate_931ah68vjHKRMeta?.path ?? "findings/:findingId()/history/:historyDate()",
    meta: _91historyDate_931ah68vjHKRMeta || {},
    alias: _91historyDate_931ah68vjHKRMeta?.alias || [],
    redirect: _91historyDate_931ah68vjHKRMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: indexn4p8ktfFGqMeta?.name ?? "projects-projectId-reporting-findings-findingId",
    path: indexn4p8ktfFGqMeta?.path ?? "findings/:findingId()",
    meta: indexn4p8ktfFGqMeta || {},
    alias: indexn4p8ktfFGqMeta?.alias || [],
    redirect: indexn4p8ktfFGqMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcqKZLTJe2ZMeta?.name ?? "projects-projectId-reporting",
    path: indexcqKZLTJe2ZMeta?.path ?? "",
    meta: indexcqKZLTJe2ZMeta || {},
    alias: indexcqKZLTJe2ZMeta?.alias || [],
    redirect: indexcqKZLTJe2ZMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: _91historyDate_93JXyNSK4JEtMeta?.name ?? "projects-projectId-reporting-sections-sectionId-history-historyDate",
    path: _91historyDate_93JXyNSK4JEtMeta?.path ?? "sections/:sectionId()/history/:historyDate()",
    meta: _91historyDate_93JXyNSK4JEtMeta || {},
    alias: _91historyDate_93JXyNSK4JEtMeta?.alias || [],
    redirect: _91historyDate_93JXyNSK4JEtMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: indexAC5wB9IXR9Meta?.name ?? "projects-projectId-reporting-sections-sectionId",
    path: indexAC5wB9IXR9Meta?.path ?? "sections/:sectionId()",
    meta: indexAC5wB9IXR9Meta || {},
    alias: indexAC5wB9IXR9Meta?.alias || [],
    redirect: indexAC5wB9IXR9Meta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91archiveId_93MmIC1PRJWoMeta?.name ?? "projects-archived-archiveId",
    path: _91archiveId_93MmIC1PRJWoMeta?.path ?? "/projects/archived/:archiveId()",
    meta: _91archiveId_93MmIC1PRJWoMeta || {},
    alias: _91archiveId_93MmIC1PRJWoMeta?.alias || [],
    redirect: _91archiveId_93MmIC1PRJWoMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/archived/[archiveId].vue").then(m => m.default || m)
  },
  {
    name: indexScrE0j62szMeta?.name ?? "projects-archived",
    path: indexScrE0j62szMeta?.path ?? "/projects/archived",
    meta: indexScrE0j62szMeta || {},
    alias: indexScrE0j62szMeta?.alias || [],
    redirect: indexScrE0j62szMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/archived/index.vue").then(m => m.default || m)
  },
  {
    name: finishedYxzpNu9iJTMeta?.name ?? "projects-finished",
    path: finishedYxzpNu9iJTMeta?.path ?? "/projects/finished",
    meta: finishedYxzpNu9iJTMeta || {},
    alias: finishedYxzpNu9iJTMeta?.alias || [],
    redirect: finishedYxzpNu9iJTMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/finished.vue").then(m => m.default || m)
  },
  {
    name: indexOZ0RFyXkAWMeta?.name ?? "projects",
    path: indexOZ0RFyXkAWMeta?.path ?? "/projects",
    meta: indexOZ0RFyXkAWMeta || {},
    alias: indexOZ0RFyXkAWMeta?.alias || [],
    redirect: indexOZ0RFyXkAWMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: newV3pJ8pIcZWMeta?.name ?? "projects-new",
    path: newV3pJ8pIcZWMeta?.path ?? "/projects/new",
    meta: newV3pJ8pIcZWMeta || {},
    alias: newV3pJ8pIcZWMeta?.alias || [],
    redirect: newV3pJ8pIcZWMeta?.redirect,
    component: () => import("/app/frontend/src/pages/projects/new.vue").then(m => m.default || m)
  },
  {
    name: _91historyDate_93ry9wFVcNwEMeta?.name ?? "templates-templateId-history-historyDate",
    path: _91historyDate_93ry9wFVcNwEMeta?.path ?? "/templates/:templateId()/history/:historyDate()",
    meta: _91historyDate_93ry9wFVcNwEMeta || {},
    alias: _91historyDate_93ry9wFVcNwEMeta?.alias || [],
    redirect: _91historyDate_93ry9wFVcNwEMeta?.redirect,
    component: () => import("/app/frontend/src/pages/templates/[templateId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: index9s2xoFsBJ0Meta?.name ?? "templates-templateId",
    path: index9s2xoFsBJ0Meta?.path ?? "/templates/:templateId()",
    meta: index9s2xoFsBJ0Meta || {},
    alias: index9s2xoFsBJ0Meta?.alias || [],
    redirect: index9s2xoFsBJ0Meta?.redirect,
    component: () => import("/app/frontend/src/pages/templates/[templateId]/index.vue").then(m => m.default || m)
  },
  {
    name: fromfindingaPH1KL2e7qMeta?.name ?? "templates-fromfinding",
    path: fromfindingaPH1KL2e7qMeta?.path ?? "/templates/fromfinding",
    meta: fromfindingaPH1KL2e7qMeta || {},
    alias: fromfindingaPH1KL2e7qMeta?.alias || [],
    redirect: fromfindingaPH1KL2e7qMeta?.redirect,
    component: () => import("/app/frontend/src/pages/templates/fromfinding.vue").then(m => m.default || m)
  },
  {
    name: indexZJJx716aGUMeta?.name ?? "templates",
    path: indexZJJx716aGUMeta?.path ?? "/templates",
    meta: indexZJJx716aGUMeta || {},
    alias: indexZJJx716aGUMeta?.alias || [],
    redirect: indexZJJx716aGUMeta?.redirect,
    component: () => import("/app/frontend/src/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93pAPqCZuHcAMeta?.name ?? undefined,
    path: _91userId_93pAPqCZuHcAMeta?.path ?? "/users/:userId()",
    meta: _91userId_93pAPqCZuHcAMeta || {},
    alias: _91userId_93pAPqCZuHcAMeta?.alias || [],
    redirect: _91userId_93pAPqCZuHcAMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: apitokensxE6nfZg71RMeta?.name ?? "users-userId-apitokens",
    path: apitokensxE6nfZg71RMeta?.path ?? "apitokens",
    meta: apitokensxE6nfZg71RMeta || {},
    alias: apitokensxE6nfZg71RMeta?.alias || [],
    redirect: apitokensxE6nfZg71RMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId]/apitokens.vue").then(m => m.default || m)
  },
  {
    name: identitiesjIaI0LHVy0Meta?.name ?? "users-userId-identities",
    path: identitiesjIaI0LHVy0Meta?.path ?? "identities",
    meta: identitiesjIaI0LHVy0Meta || {},
    alias: identitiesjIaI0LHVy0Meta?.alias || [],
    redirect: identitiesjIaI0LHVy0Meta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId]/identities.vue").then(m => m.default || m)
  },
  {
    name: indexgmy2EQd0ejMeta?.name ?? "users-userId",
    path: indexgmy2EQd0ejMeta?.path ?? "",
    meta: indexgmy2EQd0ejMeta || {},
    alias: indexgmy2EQd0ejMeta?.alias || [],
    redirect: indexgmy2EQd0ejMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId]/index.vue").then(m => m.default || m)
  },
  {
    name: mfaRfCUJxFGADMeta?.name ?? "users-userId-mfa",
    path: mfaRfCUJxFGADMeta?.path ?? "mfa",
    meta: mfaRfCUJxFGADMeta || {},
    alias: mfaRfCUJxFGADMeta?.alias || [],
    redirect: mfaRfCUJxFGADMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId]/mfa.vue").then(m => m.default || m)
  },
  {
    name: reset_45password0vcTOiLbHKMeta?.name ?? "users-userId-reset-password",
    path: reset_45password0vcTOiLbHKMeta?.path ?? "reset-password",
    meta: reset_45password0vcTOiLbHKMeta || {},
    alias: reset_45password0vcTOiLbHKMeta?.alias || [],
    redirect: reset_45password0vcTOiLbHKMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/[userId]/reset-password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indext9a7DYRpPHMeta?.name ?? "users",
    path: indext9a7DYRpPHMeta?.path ?? "/users",
    meta: indext9a7DYRpPHMeta || {},
    alias: indext9a7DYRpPHMeta?.alias || [],
    redirect: indext9a7DYRpPHMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: newmFBbp6MZGyMeta?.name ?? "users-new",
    path: newmFBbp6MZGyMeta?.path ?? "/users/new",
    meta: newmFBbp6MZGyMeta || {},
    alias: newmFBbp6MZGyMeta?.alias || [],
    redirect: newmFBbp6MZGyMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/new.vue").then(m => m.default || m)
  },
  {
    name: selfAUDD3dmxkeMeta?.name ?? undefined,
    path: selfAUDD3dmxkeMeta?.path ?? "/users/self",
    meta: selfAUDD3dmxkeMeta || {},
    alias: selfAUDD3dmxkeMeta?.alias || [],
    redirect: selfAUDD3dmxkeMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self.vue").then(m => m.default || m),
    children: [
  {
    name: disableZGe3JI0Z4mMeta?.name ?? "users-self-admin-disable",
    path: disableZGe3JI0Z4mMeta?.path ?? "admin/disable",
    meta: disableZGe3JI0Z4mMeta || {},
    alias: disableZGe3JI0Z4mMeta?.alias || [],
    redirect: disableZGe3JI0Z4mMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/admin/disable.vue").then(m => m.default || m)
  },
  {
    name: enablemD4rzmQWfIMeta?.name ?? "users-self-admin-enable",
    path: enablemD4rzmQWfIMeta?.path ?? "admin/enable",
    meta: enablemD4rzmQWfIMeta || {},
    alias: enablemD4rzmQWfIMeta?.alias || [],
    redirect: enablemD4rzmQWfIMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/admin/enable.vue").then(m => m.default || m)
  },
  {
    name: apitokensNzrX0HbFb7Meta?.name ?? "users-self-apitokens",
    path: apitokensNzrX0HbFb7Meta?.path ?? "apitokens",
    meta: apitokensNzrX0HbFb7Meta || {},
    alias: apitokensNzrX0HbFb7Meta?.alias || [],
    redirect: apitokensNzrX0HbFb7Meta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/apitokens.vue").then(m => m.default || m)
  },
  {
    name: indexqsOtAAd0dxMeta?.name ?? "users-self",
    path: indexqsOtAAd0dxMeta?.path ?? "",
    meta: indexqsOtAAd0dxMeta || {},
    alias: indexqsOtAAd0dxMeta?.alias || [],
    redirect: indexqsOtAAd0dxMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/index.vue").then(m => m.default || m)
  },
  {
    name: publickeysaNUZ8hwiZQMeta?.name ?? "users-self-publickeys",
    path: publickeysaNUZ8hwiZQMeta?.path ?? "publickeys",
    meta: publickeysaNUZ8hwiZQMeta || {},
    alias: publickeysaNUZ8hwiZQMeta?.alias || [],
    redirect: publickeysaNUZ8hwiZQMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/publickeys.vue").then(m => m.default || m)
  },
  {
    name: securityFhHzVi7ffMMeta?.name ?? "users-self-security",
    path: securityFhHzVi7ffMMeta?.path ?? "security",
    meta: securityFhHzVi7ffMMeta || {},
    alias: securityFhHzVi7ffMMeta?.alias || [],
    redirect: securityFhHzVi7ffMMeta?.redirect,
    component: () => import("/app/frontend/src/pages/users/self/security.vue").then(m => m.default || m)
  }
]
  }
]